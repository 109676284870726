import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();


  const handleProductsClick = () => {
    // If the current page is the home page, scroll to the #products section
    if (location.pathname === '/') {
      const section = document.getElementById('products');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <header className="bg-gradient-to-r from-[#383086] via-[#091F13] to-[#281E41] text-white py-4 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <div className="text-center sm:text-left mb-4 sm:mb-0">
            <h1 className="text-3xl sm:text-4xl font-bold oc-highway-font">Activated Human</h1>
            <p className="text-sm sm:text-lg mt-1 text-blue-100">AI-Powered Tools for Personal Growth</p>
          </div>
          <nav className="flex items-center space-x-6 mb-4 sm:mb-0">
            <Link to="/" className="hover:text-blue-200 transition duration-300">Home</Link>
            <Link to="/products" onClick={handleProductsClick} className="hover:text-blue-200 transition duration-300">Products</Link>
            <Link to="/about" className="hover:text-blue-200 transition duration-300">About</Link>
            <Link to="/contact" className="hover:text-blue-200 transition duration-300">Contact</Link>
          </nav>
          <div className="flex space-x-4">
            <button className="bg-white  text-blue-600 px-4 py-2 rounded-full hover:bg-blue-100 transition duration-300 text-sm font-semibold">Sign up</button>
            <button className="bg-[#65DDE5] text-white px-4 py-2 rounded-full hover:bg-blue-400 transition duration-300 text-sm font-semibold">View demo</button>
          </div>
        </div>
      </div>
    </header>
  );
}

// get a demo: #78CDF4
// start free trial: #676EAB

// ai-driven insight: #F44C39
// smart sch: #E67E6A
// continuous growth: #85E965
// proactive: #F29436
// goal acheive: #3472DE
// habit forming: #FED569

export default Header;