import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Calendar, MessageSquare, Zap, Target, Sparkles } from 'lucide-react';


const STRIPE_CHECKOUT_URL = 'https://buy.stripe.com/4gw7sCeDlaOY8y4144';


const LifeFrameworkHome = () => {
  return (
    <div className="bg-gradient-to-b from-blue-50 to-white">
      <Hero />
      <Features />
      <HowItWorks />
      <Pricing checkoutUrl={STRIPE_CHECKOUT_URL} />
      <Testimonials />
      <CallToAction />
    </div>
  );
};


const Hero = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 mb-6">
          Meet <span style={{ color: "#0000FF" }}>Blue</span>, Your AI Life Managing Assistant
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Elevate your life with personalized AI assistance that adapts to your unique rhythm and aspirations.
        </p>
        <a href={STRIPE_CHECKOUT_URL} className="bg-blue-600 text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-blue-700 transition duration-300">
            Start Free Trial
        </a>
      </motion.div>
    </div>
  </section>
);

const FeatureCard = ({ icon: Icon, title, description, iconColor }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white p-6 rounded-xl shadow-lg"
  >
    <Icon className={`w-12 h-12 mb-4`} style={{ color: iconColor }} />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

// ai-driven insight: #F44C39
// smart sch: #E67E6A
// continuous growth: #85E965
// proactive: #F29436
// goal acheive: #3472DE
// habit forming: #FED569

//
// #383086

const Features = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-5xl font-bold text-center mb-12"> Empower Your Future with <span style={{ color: "#383086" }}> Blue :)</span> </h2>
      <h2 className="text-3xl font-bold text-center mb-12"> <span style={{ color: "#383086" }}>Your Future is Bright 💡</span> </h2>      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <FeatureCard
          icon={Brain}
          title="AI-Driven Insights  👀"
          description="Harness the power of AI to uncover patterns in your behavior and receive personalized recommendations."
          iconColor="#F44C39"
        />
        <FeatureCard
          icon={Calendar}
          title="Smart Scheduling  🎯"
          description="Effortlessly manage your time with intelligent calendar integrations and productivity optimizations."
          iconColor="#775187"
        />
        <FeatureCard
          icon={MessageSquare}
          title="Proactive Assistance  ✅"
          description="Receive timely reminders, suggestions, and motivational messages tailored to your goals."
          iconColor="#F29436"
        />
        <FeatureCard
          icon={Zap}
          title="Habit Formation  🧠"
          description="Develop positive habits with AI-guided routines and progress tracking."
          iconColor="#FED569"
        />
        <FeatureCard
          icon={Target}
          title="Goal Achievement  💪"
          description="Set, track, and accomplish your personal and professional goals with strategic guidance."
          iconColor="#3472DE"
        />
        <FeatureCard
          icon={Sparkles}
          title="Continuous Growth  🌱"
          description="Experience ongoing personal development through adaptive learning and challenges."
          iconColor="#85E965"
        />
      </div>
    </div>
  </section>
);

const HowItWorks = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">How Blue Works</h2>
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div>
          <ol className="space-y-6">
            {[
              "Connect your existing tools (Todoist, Notion, Google Calendar)",
              "Answer a few questions about your goals and lifestyle",
              "Receive daily personalized insights and tasks",
              "Engage with Blue through natural conversations",
              "Track your progress and adjust your path as needed"
            ].map((step, index) => (
              <li key={index} className="flex items-center">
                <span className="bg-blue-600 text-white rounded-full w-8 h-8 flex items-center justify-center mr-4">{index + 1}</span>
                <span>{step}</span>
              </li>
            ))}
          </ol>
        </div>
        <div className="bg-gray-200 h-80 rounded-lg flex items-center justify-center">
          {/* Placeholder for an interactive demo or screenshot */}
          <p className="text-2xl text-gray-600">Interactive Demo</p>
        </div>
      </div>
    </div>
  </section>
);

const Pricing = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gray-50">
    <div className="max-w-3xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-8">Simple, Transparent Pricing</h2>
      <div className="bg-white rounded-xl shadow-lg p-8">
        <h3 className="text-2xl font-semibold mb-4">Monthly Plan</h3>
        <p className="text-5xl font-bold mb-6">$29<span className="text-xl text-gray-600">/month</span></p>
        <ul className="text-left mb-8">
          {[
            "7-day free trial, just for you :)",
            "Unlimited AI tasks & prompts",
            // "Full integration with Todoist, Notion, and Google Calendar",
            "Daily personalized insights, reminders, & assistance",
            "24/7 proactive life-enhancing strategy & guidance"
          ].map((feature, index) => (
            <li key={index} className="flex items-center mb-2">
              <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
              {feature}
            </li>
          ))}
        </ul>
        <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-blue-600 text-white font-semibold py-3 px-8 rounded-full text-lg hover:bg-blue-700 transition duration-300">
          Start Using Activated Human Now
        </a>
      </div>
    </div>
  </section>
);

const Testimonials = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8">
    <div className="max-w-7xl mx-auto">
      <h2 className="text-4xl font-bold text-center mb-12">What Our Users Say</h2>
      <div className="grid md:grid-cols-3 gap-8">
        {[
          {
            quote: "Blue has transformed the way I approach my daily tasks and long-term goals. It's like having a personal life coach available 24/7.",
            author: "Sarah K., Entrepreneur"
          },
          {
            quote: "The personalized insights I get from Blue have helped me break through plateaus in both my personal and professional life.",
            author: "Michael R., Software Engineer"
          },
          {
            quote: "I was skeptical at first, but Blue's ability to adapt to my changing needs and provide relevant advice is truly impressive.",
            author: "Emily T., Artist"
          }
        ].map((testimonial, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-md">
            <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
            <p className="font-semibold">{testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <section className="py-20 px-4 sm:px-6 lg:px-8 bg-blue-600 text-white">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-4xl font-bold mb-6">Ready to Optimize Your Life?</h2>
      <p className="text-xl mb-8">Join thousands of users who are already experiencing the benefits of AI-driven personal growth.</p>
      <a href={STRIPE_CHECKOUT_URL} className="inline-block bg-white text-blue-600 font-semibold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
        Start Your Free Trial Now
      </a>
    </div>
  </section>
);

export default LifeFrameworkHome;