import React from "react";
import { motion } from 'framer-motion';

const Hero = () => (
  <section className="relative bg-gray-900 text-white overflow-hidden">
    {/* Responsive header image with reduced height and cropping */}
    <div className="w-full h-0 pb-[30%] relative overflow-hidden">
      <img 
        src="/images/Olivia-Steele-Hero.png" 
        alt="Everything you need is inside of you" 
        className="absolute top-0 left-0 w-full h-auto object-cover clip-hero-image"
      />
    </div>

    {/* Existing hero content */}
    <div className="relative py-20">
      <div className="container mx-auto px-4 text-center relative z-10">
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-5xl md:text-6xl font-bold mb-6"
        >
          Power Smarter Personal Growth
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xl md:text-2xl mb-8 max-w-3xl mx-auto text-gray-300"
        >
          Discover AI-driven tools designed to elevate your life and accelerate your learning journey.
        </motion.p>
        <motion.button
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-white text-blue-900 px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-100 transition duration-300"
          onClick={() => document.getElementById('products').scrollIntoView({ behavior: 'smooth' })}
        >
          Explore Our Products
        </motion.button>
      </div>
    </div>
  </section>
);

export default Hero;