import React from 'react';
import { Link } from 'react-router-dom';

import { ArrowRight, Brain, Volume2 } from 'lucide-react';


const products = [
    {
      id: 'life-framework',
      name: 'Life Framework',
      description: 'Empower your personal growth with AI-driven insights and strategies.',
      image: '/public/images/dalle_1.webp', // Replace with actual image path
      path: '/life-framework',
      icon: Brain
    },
    {
      id: 'home-automation',
      name: 'Home Assistant',
      description: 'Integrate your home environment with your daily rhythm and life framework.',
      image: '/api/placeholder/600/400', // Replace with actual image path
      path: '/home-automation',
      icon: Volume2
    },
    {
      id: 'linguaboost',
      name: 'LinguaBoost',
      description: 'Expand your vocabulary in a new language effortlessly throughout your day.',
      image: '/api/placeholder/600/400', // Replace with actual image path
      path: '/linguaboost',
      icon: Volume2
    }
  ];

const ProductCard = ({ product }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // For smooth scrolling
    });
  };
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
      <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
      <div className="p-6">
        <h3 className="text-xl font-semibold mb-2">{product.name}</h3>
        <p className="text-gray-600 mb-4">{product.description}</p>
        <Link
          to={product.path}
          onClick={scrollToTop}
          className="inline-flex items-center text-blue-600 hover:text-blue-800 transition-colors duration-200"
        >
          Learn More <ArrowRight className="ml-2 h-4 w-4" />
        </Link>
      </div>
    </div>
  );
};

const ProductShowcase = () => {
  return (
    <section id="products"  className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Our Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map(product => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductShowcase;
